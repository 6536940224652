















































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ISessionData } from '@/interfaces';
import {
  readQuestionData,
  readQuestionResponses,
  readQuestionTopics,
  readSessionAIRecruitment,
} from '@/store/sessionData/getters';
import QuestionGraphTabNew from '@/components/QuestionGraphTabNew.vue';
import EstimatesTabNew from '@/components/EstimatesTabNew.vue';
import VueApexCharts from 'vue-apexcharts';
import NominalQuestionGraphTab from '@/components/NominalQuestionGraphTabNew.vue';
import TopicsTab from '@/components/TopicsTabNew.vue';
import DriversTab from '@/components/DriversTabNew.vue';
@Component({
  components: {
    VueApexCharts,
    'question-graph-tab': QuestionGraphTabNew,
    'estimates-tab': EstimatesTabNew,
    'nominal-question-graph-tab': NominalQuestionGraphTab,
    'topics-tab': TopicsTab,
    'drivers-tab': DriversTab,
  },
})
export default class Results extends Vue {
  @Prop(Object) public sessionData!: ISessionData;
  @Prop(Object) public sessionDataOpenEnded!: object;

  public questionView = false;
  public questionId = '';
  public agentsRunning = false;
  public questionData(questionId: string) {
    return readQuestionData(this.$store)(questionId);
  }

  public questionResponseCount(questionId: string) {
    const responses = readQuestionResponses(this.$store)(questionId);
    return responses ? responses.participants_count : 0;
  }

  get radialBarOptions() {
    return {
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 10,
              show: true,
              fontSize: '30px',
              fontWeight: 800,
              color: '#ffffff'
            },
          },
        },
      },
      chart: {
        id: 'vuechart-example'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
    };
  }

  public openEndedTopicData(questionId: string) {
    return this.sessionDataOpenEnded[questionId];
  }

  public openEndedTopicDataOrdered(questionId: string) {
    return Object.entries(
      this.sessionDataOpenEnded[questionId].topics).sort((a, b) => { return (b[1] as number) - (a[1] as number);}
    );
  }

  public openEndedSeries(questionId: string) {
    return [{ 'data': this.openEndedTopicDataOrdered(questionId).map(x => x[1]), 'name': 'Count' }];
  }

  public getOpenEndedCategories(questionId: string) {
    return this.openEndedTopicDataOrdered(questionId).map(x => x[0]);
  }

  public openEndedChartOptions(questionId) {
    return {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          barHeight: 50,
          horizontal: true,
          dataLabels: {
            position: 'top'
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: 40,
        offsetY: 0,
        formatter: (val) => {
          return `${val}%`;
        }
      },
      xaxis: {
        categories: this.getOpenEndedCategories(questionId),
        type: 'category',
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          },
          formatter: (val) => {
            return `${val}%`;
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          }
        },
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: false
      },
      fill: {
        colors: ['#6279BE']
      },
    };
  }

  public getFormattedConversion(started, finished) {
    if (!started || !finished) {
      return 0;
    }
    return Math.round(finished / started * 100);
  }

  startedOpened() {
    return [this.getFormattedConversion(this.sessionData.p_started, this.questionResponseCount(this.sessionData.questions_with_data[0].id))];
  }

  openedCompleted() {
    return [this.getFormattedConversion(this.sessionData.p_started, this.sessionData.p_finished)];
  }

  startedCompleted() {
    return [this.getFormattedConversion(this.questionResponseCount(this.sessionData.questions_with_data[0].id), this.sessionData.p_finished)];
  }

  public getFormattedCompletionTime(time: number) {
    const hours = Math.floor(time / 3600);
    time = time - hours * 3600;
    const minutes = Math.floor(time / 60);
    const seconds = Math.trunc(time % 60);
    if (hours === 0) {
      if (minutes === 0) {
        return seconds.toString() + ' seconds';
      }
      return minutes.toString().concat(' minutes ', seconds.toString(), ' seconds');
    }
    return hours.toString().concat(
      ' hours ', minutes.toString(), ' mins ', seconds.toString(), ' secs'
    );
  }

  public questionTopics(questionId: string) {
    return readQuestionTopics(this.$store)(questionId);
  }

  copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  copyQuestionLink(questionId) {
    const url = window.location.origin + '/results/' + this.$route.params.id + '?question=' + questionId;
    this.copyToClipboard(url);
  }

  exportToPdf(index) {
    console.log('Exporting to PDF');
  }

  get sessionAIData() {
    return readSessionAIRecruitment(this.$store);
  }

  get agentsRunningProgress() {
    if (!this.sessionAIData || !this.sessionAIData.progress) {
      return 0;
    }
    return this.sessionAIData.progress.toFixed(0);
  }

  public switchToDetails() {
    this.$emit('switchToDetails');
  }
}



