




















































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VueSocials from 'vue-socials';

Vue.use(VueSocials);
@Component
export default class SocialMediaShareButtons extends Vue {
  @Prop(String) public studyUrl!: string;
  @Prop(String) public studyTitle!: string;

  public hasCounter = false
  public useNativeBehavior = false
  public windowFeatures = {'width': 685,'height': 600}
  public shareMessage = 'Join my study on Cardinal!'
  public shareTags = ['#Cardinal']
  public shareOptions = {
    url: this.studyUrl,
    hashtag: this.shareTags,
    text: this.shareMessage,
    title: this.studyTitle,
    selftext: this.shareMessage,
  }
  public emailShareOptions = {
    su: `A cardinal by me_ ${this.studyTitle}`,
    body:`${this.shareMessage}\n${this.studyUrl}`
  }
  public twitterShareOptions = {
    url: this.studyUrl,
    text:`${this.shareMessage}\n${this.studyUrl}`,
    hashtags: this.shareTags,
    via:'twitterdev'
  }
  public numberShareOptions = {
    text:`${this.shareMessage}\n${this.studyUrl}`
  }
  public descriptionShareOptions = {
    url: this.studyUrl,
    name: this.studyTitle,
    description:`${this.shareMessage}\n${this.studyUrl}`
  }

  @Emit()
  public openSlackShare() {
    return true;
  }
}
